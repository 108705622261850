import React from "react";
import * as s from "./subscribeBlockBlog.module.css";
import axios from "axios";
import { useRef, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import CheckboxDropdown from "../checkboxSelect/checkboxSelect";

const config = {
    baseURL: "https://webhook.site/427d7f1c-5c86-498c-b25a-4f5f31afd6a2",
    headers: { "Content-Type": "application/json" },
    method: "post",
    timeout: 3000,
};
const instance = axios.create(config);

const SubscribeBlockBlog = () => {
    const form = useRef();

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [selectedValues, setSelectedValues] = React.useState([]);
    const [errors, setErrors] = React.useState({
        firstName: null,
        lastName: null,
        email: null,
    });
    const [isOpen, setIsOpen] = React.useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const handleDropdownChange = (selectedOptions) => {
        setSelectedValues(selectedOptions);
    };
    const [sending, setSending] = React.useState(false);
    const [messageSent, setMessageSent] = React.useState(false);


    const submitForm = (event) => {
        event.preventDefault();
        if (isValidForm()) {
            setSending(true);
            const queryParams = JSON.parse(sessionStorage.getItem("queryParams"));
            const lines = [];
            try {
                for (var x in queryParams) {
                    lines.push(`*${x}:* ${queryParams[x]}`)
                }
            } catch (e) { }

            const payloadForm = {
                event: "subscriber",
                source: "orders.co",
                utm_source: queryParams?.utm_source || "",
                firstName: !!firstName ? firstName : "",
                lastName: !!lastName ? lastName : "",
                email: !!email ? email : "",
                contentType: !!selectedValues ? selectedValues : ""
            };
            const payload = {
                ...payloadForm, details: lines.join("\n")
            }

            instance
                .request({ data: JSON.stringify(payload) })
                .then(function (response) {
                    // handle success
                    console.log("Subscribe Form Was Submitted Successfully");
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    window.dataLayer.push({ ...payloadForm });
                    setFirstName("");
                    setLastName("");
                    setEmail("");
                    setSelectedValues([]);
                    setErrors({
                        firstName: null,
                        lastName: null,
                        email: null,
                    });
                    setMessageSent(true);
                    // always executed
                });
        }
    }

    const isValidForm = () => {
        let isValid = true;
        let newErrors = {
            firstName: null,
            lastName: null,
            email: null,
        };

        if (!firstName?.length) {
            isValid = false;
            newErrors.firstName = 'Please enter Your Name';
        }
        if (!lastName?.length) {
            isValid = false;
            newErrors.lastName = 'Please enter Your Name';
        }
        if (!isEmailValid(email)) {
            isValid = false;
            newErrors.email = 'Please enter a valid Email';
        }

        setErrors(newErrors);
        return isValid;
    }

    const isEmailValid = (email) => {
        const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return re.test(String(email).toLowerCase());
    }
    return (
        <div className={`${s.formBox}`}>
            <div className="container">
                {!!messageSent ?
                    <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
                        <div className={`${s.thankYou} text-center`}>
                            <span>
                                Thank you!
                                <br /> You are Subscribed!
                            </span>
                        </div>
                    </div>
                    : 
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-4">
                            <h3 className={`${s.formBoxTitle}`}>Your Inbox, Your Rules!</h3>
                            <h4 className={s.formBoxSubtitle}>Tailor your newsletter
                                with the topics
                                you're most interested in.</h4>
                        </div>
                        <div className="col-12 col-md-5">

                            <form onSubmit={submitForm} className="row justify-content-center">
                                <input type="hidden" name="form_type" value="subscribe_form" />
                                <div className="col-12 col-xl-6 px-lg-2">
                                    <label>First Name</label>
                                    <input type="text" name="firstname" className={`${null != errors.firstName ? s.invalid : ''}`}
                                        value={firstName} onChange={e => setFirstName(e.target.value)} required />
                                </div>
                                <div className="col-12 col-xl-6 px-lg-2">
                                    <label>Last Name</label>
                                    <input type="text" name="lastname" className={`${null != errors.lastName ? s.invalid : ''}`}
                                        value={lastName} onChange={e => setLastName(e.target.value)} required />
                                </div>
                                <div className="col-12 px-lg-2">
                                    <label>Email</label>
                                    <input type="email" name="email" className={`${null != errors.email ? s.invalid : ''}`}
                                        value={email} onChange={e => setEmail(e.target.value)} required />
                                </div>
                                <div className="col-12 px-lg-2">
                                    <label>Preferred Content Type</label>
                                    <CheckboxDropdown options={[
                                        'Industry News',
                                        'Industry Reports',
                                        'Expert Insights',
                                        'Tips & Tricks',
                                        'Product Updates',
                                        'Exclusive Offers',
                                        'Events & Webinars',
                                        'Customer Spotlights',
                                    ]}
                                        selectedValues={selectedValues}
                                        onChange={handleDropdownChange} />
                                </div>
                                <div className="col-12 px-lg-2">
                                    <button type="submit" disabled={sending}>Subscribe</button>
                                </div>
                            </form>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default SubscribeBlockBlog;
